.about{
    &-content{
        margin-top: 1em;
        margin-bottom: 1em;
        @include setMinRwd{
            margin-top: get-vw(50px);
            margin-bottom: get-vw(70px);
        }

        &-graybottom{
            &::before{
                content: '';
                display: block;
                @include setSize(calc(100% + 2rem),100%);
                height: auto;
                min-height: 15.5em;
                // min-height: 17.5em;
                background-color: #f0f0f0;
                position: absolute;
                left: -1em;
                bottom: -1.95em;
                // top: 50%;
            }

            &.second{
                &::before{
                    min-height: 24em;
                }
            }
        }
    }

    &-txtCont{
        --min-size: 18;
        --max-size: 20;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        .unreset{
            @include setMinRwd{
                padding-right: 30px;
            }
        }

        &.txtCont-bg{
            @include setBackSet(top,100%);
            background-image: url('../images/about-img03.png');
            padding: 10.5em 4em;
            margin-top: 50px;
            margin-bottom: 25px;
            --min-size: 16;
            --max-size: 16;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            line-height: 2;

            @include setMaxRwd{
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 1em;
                margin-top: 1.5em;
                margin-bottom: 1em;
            }
        }
    }
}

// 
$timeline-line-color: #dbceeb !default;
$timeline-line-width: 1px !default;
$timeline-icon-color: #e4deea !default;

.timeline {
    display: flex;
    flex-direction: column;
    
    &__item {
      padding: 20px 20px 20px 50px;
      position: relative;
      
      &::before {
        background: $timeline-line-color;
        content: "";
        height: 100%;
        left: calc(32px / 2);
        position: absolute;
        top: 0;
        width: $timeline-line-width;
      }
      
      &::after {
        box-shadow: inset 0px 0px 0px 5px $timeline-icon-color;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 20px;
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      
      & :last-child {
        margin-bottom: 0;
      }
    }

    &__date{
        color: $colorMain;
        --min-size: 20;
        --max-size: 21;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        @extend .fw-600;
        font-family: $Arimo;
        margin-bottom: 0.65em;
    }

    &__text{
        color: #636364;
        @extend .fw-400;
    }
    
    &__item--checked {
      &::after {
        // content: '\2713';
        // color: #fff;
        content: url('../images/timeline-icon-circle-small.png');
        text-align: center;
        background-color: $timeline-icon-color;
      }
    }

    @include setMinRwd{
        &.timeline--horizontal {
            flex-direction: row;
            margin-top: 4rem;
            margin-bottom: 5rem;
            
            .timeline__item {
              padding: 70px 20px 20px 50px;
              flex: 1 1 0px;
              
              &::before {
                height: $timeline-line-width;
                top: calc(32px / 2);   
                width: 100%;
              }
              
              &::after {
                top: 0;
                left: 50px;
              }
            }
        }
    }
}