// 產品項目說明
.pro-heading{
    &-des{
        border-bottom: 1px solid #676767;
        margin-top: 20px;
        margin-bottom: 45px;
    }

    &-img{
        margin-bottom: 20px;
    }

    &-txt{
        color:#464545;
        @include fontSize(18px);
        padding: 10px 10px 15px;
    }
}

// 產品詳細頁
.Prod{
    &-conInfo{
        &-img{
            @include radius(20px);
            background-color: #f6f6f6;
            overflow: hidden;
            height: 0;
            padding-bottom: 110%;
            img{ 
                @extend %imgCenter;
                width: auto;
                transition: 0.25s;
            }

            @include setMaxRwd{
                padding-bottom: 125%;
            }
        }

        &-info{
            padding-top: 0.5em;
            @include setMinRwd{
                padding-left: 2em;
            }
        }

        @include setMinRwd{
            margin-top: 4.5em;
        }
    }

    &-heading{
        --min-size: 22;
        --max-size: 24;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        @extend .fw-600;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    &-conTxt{
        border-top: #bfbfbf 1px solid;
        border-bottom: #bfbfbf 1px solid;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        &-box{
            background-color: #f8f8f9;
            padding: 1.25em 1.5em;
            line-height: 1.75;
        }
    }

    &-download-btn{
        .btn{
            border-radius: 30px !important;
            border: $colorMain 1px solid;
            padding-left: 2em;
            padding-right: 2em;
        }

        .icon{
            margin-top: -3px;
        }
    }

    &-unreset{
        padding-top: 1.5em;
        margin-top: 1em;
        .nav-tabs{
            border-top: #000 3px solid;
            border-bottom-width: 2px;
            border-bottom-color: #bfbfbf;
        }

        .nav-item {
            min-height: auto;
            &::before{
                display: none !important;
            }
        }

        .nav-link{
            position: relative;
            @include fontSize(16px);
            color: #7d7c7c;
            @extend .fw-600;
            background-color: transparent !important;
            border: none;
            padding-right: 30px !important;
            padding-left: 30px !important;

            &.active{
                color: $colorBlack !important;
                &::after {
                    content: "";
                    display: block;
                    @include setSize(70%,3px);
                    background-color: $colorMain;
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            @include setMinRwd{
                @include fontSize(18px);
                line-height: 2.5;
            }
        }

        .tab-content{
            padding: 1em;
            @include setMinRwd{
                padding: 1.75em 2em;
            }
        }
    }
}