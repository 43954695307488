.pro{
    &-card-wrap{
        @include setMinRwd{
            margin-top: 3em;
        }

        // 列表
        .card{
            background-color: transparent;
            
            @include radius(10px);
            background-color: #f6f6f6;
            border:#f6f6f6 1px solid;
            overflow: hidden;

            &:hover{
                background-color: #fff;
            }
    
            &-img-top{
                overflow: hidden;
                height: 0;
                padding-bottom: 125%;
                img{ 
                    @extend %imgCenter;
                    width: auto;
                    transition: 0.25s;
                }
            }
    
            // 
            &-title{
                --min-size: 20;
                --max-size: 24;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                margin-bottom: 1rem;
                @extend .fw-600;
                text-align: center;
                line-height: 1.05;
            }

            &-btn {
                width: auto;
                border-radius: 30px !important
            }

            &-linkNormal{
                @include setMinRwd{
                    &:hover{

                        & ~ .card-img-top{
                            img{
                                transform: scale(1.25);
                            }
                        }

                        & ~ .card-body{
                            .card-btn{
                                background-color: $colorMain !important;
                            }
                        }
                    }
                }
            }

            @include setMinRwd{
                margin-bottom: 3em;
                &-img-top{
                    padding-bottom: 100%;
                }
            }
        }
    }
}