@mixin foot-flexSB{
  display: flex;
  justify-content: space-between;
}

footer {
  padding: 1em;
  background-color: $colorFooter;

  &,a{
    color: #fff;
  }

  .tag-item {
    font-size: 15px;
    background-color: #161616;
    color: #bfbfbf;
    padding: 5px 5px 6px;
  }

  .footer {
    &-wrapper{
      @include setMinRwd{
        @include foot-flexSB;
      }
    }
    
    &-info{
      p{
        margin-bottom: 10px;
      }
      
      .list-group-item{
        background-color: transparent;
        color: $colorSecondary;
        padding-left: 0;
        padding-bottom: 0;
        border: none
      }

      .copyright{
        @include fontSize(15px);
        color: $colorSecondary;
      }

      &-logo{
        padding-bottom: 0.65em;
        & ~ p{
          padding-left: 50px;
          span.text-muted{
            position: absolute;
            left: 0;
            color: #adabab !important;
          }
        }
      }

      &-left{
        &,
        &-row{
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }

        &-item{
          @include setMinRwd{
            flex: none;
            max-width: 20em;
          }
        }

        &-row{
          justify-content: space-around;
          padding-top: 70px;
          @include setMinRwd{
            padding-left: 4em;
            padding-right: 4em;
          }
        }

        @include setMaxRwd{
          flex-direction: column;
          &-row{
            padding-top: 1.5em;
            li{
              flex: 0 0 50%;
              margin-bottom: 1em;
            }
          }
        }
      }

      &-right{
        padding-top: 70px;

        .iware{
          margin-top: 0.5em;
        }

        @include setMaxRwd{
          padding-top: 1.5em;
          p{
            font-size: 13px !important;
          }
        }
      }
    }

    &-link{
      &-head{
        font-size: 18px;
        margin-bottom: 25px;
        &::before{
          content: '';
          display: block;
          @include setSize(23px,2px);
          background-color: #fff;
          position: absolute;
          bottom: -12px;
        }
      }
    }
  }

  @include setMinRwd{
    padding: 45px 25px 40px;
  }

  @include setMaxRwd{
    padding-bottom: 4em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.iware {
  display: inline-block;
  background: #161616;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
  &, a{
    color: #bfbfbf;
  }
  
  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 15px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
    
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: $colorMain;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      padding: 6px;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        margin-left: 0;
        small{
          font-size: 100%;
          padding-right: 5px;
        }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  @include fontSize(16px);
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
  
  p {
    font-weight: 400;
    text-align: center;
    line-height: 2;

    @include setMinRwd{
      text-align: left;
      margin-bottom: 0;
    }
  }

  .btn {
    text-decoration: none !important;
    border-radius: 10px !important;

    @include setStyleMinRwd(800px){
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
    }

    @include mobile{
      display: block;
      width: 100%;
      @include fontSize(16px);
    }
  }

  &-wrapper{
    @extend %setFlex;
    flex-direction: column;
    align-items: center;

    @include setMinRwd{
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  &.close-cookies {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
    @include pad {
      bottom: -2%;
    }
  }

  @include setMinRwd{
    .btn {
      @include fontSize(18px);
      margin-left: 2.5em;
      padding: 0.5rem 2.5rem;
    }
  }

  @include pad {
    width: calc(100% - 1em);
    border-top: none;
    margin: .5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include fontSize(14px);
    
    &.display { 
      flex-direction: column;
    }
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}