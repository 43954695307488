// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: $OpenSans-300;
    --fancy-slider-title-line-height: 1;

    /* Card */
    --card-transition-duration: 500ms;
    --card-transition-delay: 250ms;    /* delay background color */
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 1;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        padding-top: 2em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding: 1.5em 0.5em;
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                width: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        &Swiper{
            width: 100%;
            overflow: hidden;
            .swiper-wrapper{
                /* 通过改变animation-timing-function 制作弹性切换效果 */
                transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
            }

            // 共用
            .swiper-button-wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                top: -2em;
                .swiper-button-prev,
                .swiper-button-next{
                    @include setSize(47px,47px);
                    overflow: hidden;
                    
                    &::after {
                        display: none;
                    }

                    svg{
                        path{
                            fill: $colorMain;
                        }
                    }

                    &.swiper-button-disabled {
                        svg{
                            path{
                                fill: #bfbfbf;
                            }
                        }
                    }

                    @include setMinRwd{
                        position: static;
                    }
                }
            }
        }

        // 標題用
        &Head{
            
        }

        // 內文用
        &Article{
            line-height: 1.5;
        }

        &About{
            padding-top: get-vw(100px);
            padding-bottom: get-vw(60px);

            @include setBackSet(top,100%);
            background-image: url('../images/index-pageAbout-bg.jpg');

            .pageGrid{
                align-items: center;
            }

            .pageAbout-img{
                @include setMinRwd{
                    width: get-vw(627px);
                }
            }

            .pageAbout-info{
                &-article{
                    .article{
                        --min-size: 17;
                        --max-size: 18;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                        line-height: 2;
                    }
                    
                    @include setMinRwd{
                        padding-left: get-vw(40px);
                        .article{
                            margin-top: 1em;
                            margin-bottom: 4em;
                        }
                    }
                }

                @include setMinRwd{
                    width: calc(100% - 30px);
                    padding-left: get-vw(40px);
                    padding-right: get-vw(80px);
                }
            }

            .btn-wrap{
                justify-content: start;
            }

            .btn{
                flex: 1;
                display: inline-flex;
                justify-content: space-between;
                margin: 0.75em 1em 0.75em 0;

                @include setMinRwd{
                    max-width: 200px;
                }

                &:hover{
                    path{
                        fill: #fff;
                    }
                }
            }

            &-spec{
                width: 100%;
                margin-top: 2em;

                .spec-box{
                    width: 100%;
                }

                @include setMinRwd{
                    display: flex;
                    align-items: flex-start;
                    padding-left: 2em;
                    padding-right: 2em;

                    .spec-box,
                    .spectxt-box{
                        flex:1
                    }

                    .title-box{
                        position: absolute;
                        top: 7em;
                        .heading-title-article {
                            max-width: 500px;
                        }
                    }
                }

            }

            @include setMinRwd{
                margin-top: get-vw(-100px);;
            }

            @include setMaxRwd{
                .pageGrid{
                    display: block;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        &Video{
            &-swiper{
                .swiper-slide-iframe{
                    @include radius(1.1em);
                    overflow: hidden;
                }

                .swiper-slide-head{
                    max-width: 16em;
                    text-align: center;
                    font-size: 20px;
                    @extend .fw-900;
                    padding-top: 2em;
                    display: flex;
                    align-items: center;
                    min-height: 5em;
                    
                }

                .swiper-button-wrapper{
                    @include setMinRwd{
                        max-width: 65%;
                        margin: auto;
                    }
                }
            }
        }

        &Explore{
            @include setBackSet(top,cover);
            background-image: url('../images/index-pageExplore-bg.jpg');
            &-swiper{
                padding-top: 4em;
                padding-bottom: 1em;
                padding-left: 2.5em;
                padding-right: 2.5em;
                .swiper-button-wrapper {
                    position: static;
                    .swiper-button-prev,
                    .swiper-button-next{
                        position: absolute;
                        &.swiper-button-disabled{
                            svg path{
                                fill: $colorMain;
                            }
                        }
                    }
                    
                    .swiper-button-prev{
                        left: 0em;
                    }

                    .swiper-button-next{
                        right: 0em;
                    }
                }

                @include setMaxRwd{
                    padding-top: 0em;
                    margin-top: -2em;
                    .swiper-container{
                        padding-top: 4em;
                        overflow: hidden;
                    }
                }
            }

            .card{
                max-width: 100% !important;
                flex: 0 0 100% !important;
                margin-left: 4em;
                margin-right: 4em;
                background-color: transparent;
                align-items: center;
                
                *{
                    transition: 0.2s;
                }

                &-head{
                    --min-size: 20;
                    --max-size: 24;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    text-align: center;
                    margin-top: 0.5em;
                    margin-bottom: 0.75em;
                    padding-left: 0.75em;
                    padding-right: 0.75em;
                    line-height: 1.05;
                    @extend .fw-600;
                }

                &-img-top{
                    position: relative;
                    padding: 2em 4em;

                    &::before{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        background-color: #fff;
                        border-radius: 50%;
                        position: absolute;
                        top: 2.5em;
                        left: 0;
                        z-index: -1;
                    }
                }
                
                &-btn{
                    color: #fff !important;
                    background-color: #7f7f7f !important;
                    border-radius: 30px !important;
                    display: flex;
                    justify-content: space-between;
                    max-width: 50%;
                }

                &::before{
                    content: "";
                    display: block;
                    @include setSize(calc(100% + 5em),0);
                    padding-bottom: calc(100% + 12.75em);
                    background-color: #e5e5e5;
                    @include radius(27em);
                    position: absolute;
                    top: -1.75em;
                    z-index: -1;
                }
            }

            @include setMinRwd{
                padding-top: get-vw(60px);
                padding-bottom: get-vw(60px);
                .card{
                    &:hover{
                        transform: translateY(-1.1em);
                        .card-head{
                            color: #fff;
                        }

                        .card-img-top{
                            img{
                                transform: translateY(4.5em) scale(1.25);
                            }
                        }
                        
                        .card-btn{
                            background-color: $colorMain !important;
                        }

                        &::before{
                            background-color: #7e659e;
                            box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.35);
                        }
                    }
                }
            }

            @include setMaxRwd{
                .card{
                    margin-left: 3em;
                    margin-right: 3em;
                    &-img-top {
                        padding-left: 3em;
                        padding-right: 3em;
                    }
                    &::before {
                        width: calc(100% + 4em);
                    }
                }
            }
        }
    }
}


// 特點製作
.spec-box{
    display: flex;
    align-items: stretch;
    justify-content: center;
    
    @include setMaxRwd{
        flex-direction: column;
        .specinfo-box{
            .specinfo-dot{
                display: none;
            }
        }

        .specimg-box{
            order: -1;
            margin-top: 2em;
            margin-bottom: 2em;
            
            .specinfo-dotNum-box{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                .specinfo-dotNum{
                    position: absolute;
                    &:nth-child(1){
                        bottom: 3em;
                        left: 55%;
                    }

                    &:nth-child(2){
                        bottom: 0;
                        left: 35%;
                    }

                    &:nth-child(3){
                        top: 40%;
                        left: 60%;
                    }

                    &:nth-child(4){
                        top: 50%;
                        left: 60%;
                    }

                    &:nth-child(5){
                        top: 75%;
                        left: 60%;
                    }
                }
            }
        }
    }
}

.specinfo-txt{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .title{
        display: inline-flex;
        align-items: center;
        color: #fff;
        background-color: $colorMain;
        padding: 0.5em 1em;
    }

    .des{
        padding: 0.75em;
    }
}

.specimg-box{
    text-align: center;
}

.specinfo-box{
    display: flex;
    align-items: flex-start;

    .specinfo-txt{

    }

    .specinfo-dot{
        padding: 1em;
    }
}

.spectxt-box{
    display: flex;
    flex-direction: column;
    z-index: 2;
    
    .specinfo-txt{
        .title{
            border-radius: 20px 0px 0px 20px;
        }
    }

    &.left{

        @include setMinRwd{
            justify-content: flex-end;

            &,
            .specinfo-txt{
                align-items: flex-end;
            }

            .specinfo-txt{
                .title{
                    padding-right: 0.65em;
                }
                .des{
                    text-align: right;
                    padding-right: 0;
                }
            }

            li{
                &:nth-child(1){
                    .specinfo-box{
                        right: -3em;
                        top: 2.5em;
                        width: calc(100% + 5.5em);
                    }
                }
                &:nth-child(2){
                    .specinfo-box{
                        right: -4.5em;
                    }
                }
            }
        }
    }

    &.right{
        justify-content: center;
        .specinfo-txt{

            .des{
                text-align: left;
            }
        }

        .specinfo-box{
            .specinfo-dot{
                order: -1;
            }
        }

        @include setMinRwd{
            .specinfo-txt{
                .title{
                    border-radius: 0px 20px 20px 0px;
                    padding-left: 0.65em;
                }
            }

            li{
                &:nth-child(1){
                    .specinfo-box{
                        left: -2.6em;
                        top: 2.85em;
                    }
                }

                &:nth-child(2){
                    .specinfo-box{
                        top: 4.55em;
                        left: -2.55em;
                        .specinfo-dot {
                            top: -1.55em;
                        }
                    }
                }

                &:nth-child(3){
                    .specinfo-box{
                        top: 5em;
                        left: -3.5em;
                        .specinfo-dot {
                            top: -0.55em;
                        }
                    }
                }
            }
        }
    }
}

.specinfo-dotNum{
    width: 38px;
    height: 38px;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 50%;
    padding: 4px;
    
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #fff;
        font-size: 14px;
        font-family: Arial;
        font-weight: 600;
        background-color: $colorMain;
        @include radius(50%);
        transition: all .4s ease;
    }
}

// 

.titlebox{
    display: inline-block;
    padding-right: get-vw(70px);
    padding-top: get-vw(75px);
    border-right: $colorBase 1px solid;
}


.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            background: linear-gradient(to bottom,  #ffffff 0%,#f7f7f7 60%,#ffffff 60%,#f7f7f7 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            @include setMaxRwd{
               height: get-vw-mobile(300px);
            }
        }

        &-main{
            overflow: visible;
            &::before{
                content: '';
                display: block;
                @include setSize(100%,100%);
                max-width: 449px;
                max-height: 120px;
                @include setBackSet(top,contain);
                background-image: url('../images/main-banner-bg.png');
                position: absolute;
                top: -40%;
                left: 30%;
                z-index: -1;
            }

            @include mobile{
                img{
                    height: 100px;
                    object-fit: cover;
                }

                &::before{
                    top: -50%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    
    // 共用Swiper
    &-gallerSwiper{
        overflow: hidden;
        // swiper 樣式
        .swiper{
            &-pagination{
                width: fit-content;
                display: inline-flex;
                align-items: center;
                gap: 8px;
                &-bullet {
                    @include setSize(50px,5px);
                    @include radius(0);
                    margin: 0 !important;
                    &-active {
                        background-color: $colorMain;
                    }
                }
            }
        }

        // 卡片內容
        .card{
            &-body{
                padding: 0;
            }

            &-title{
                font-weight: 400;
                line-height: 1.15;
                word-wrap: normal;
            }

            &-img-top{
                text-align: center;
                img{
                    display: inline-block;
                }
            }
        }

        .swiper{
            &-pagination{
                bottom: get-vw(10px) !important;

                @include setStyleMinRwd(760px){
                    &-bullet {
                        opacity: 0.2;
                        &-active{
                            opacity: 1;
                        }
                    }
                }

                @include setMaxRwd{
                    justify-content: center; 
                }
            }
        }

        // 首頁產品分類 swiper
        &-pro{
            width: 100%;
            padding-top: get-vw(15px);
            padding-bottom: get-vw(40px);
            .card{
                &-body{
                    @include setSize(100%,100%);

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include setMinRwd{
                        position: absolute;
                        padding: 3em;
                        color: #fff;
                        background-color: rgba(49, 49, 49,0.85);
                    }
                }

                &-title{
                    @include fontSize(42px);
                    font-family: var(--fancy-slider-title-font-family);
                    border-bottom: $colorBase 1px solid;
                    text-align: center;
                    padding-bottom: 0.3em;
                    margin-bottom: 0.35em;

                    @include setMinRwd{
                        border-bottom-color: rgba(255,255,255,0.65); 
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }

                @include setMinRwd{
                    &-linkNormal{
                        & ~ .card-body{
                            opacity: 0;
                            transition: var(--card-transition-duration);
                            .card-title,
                            .card-des{
                                transition-duration: var(--card-transition-duration);
                                transition-delay: var(--card-transition-delay);
                            }
    
                            .card-title{
                                transform: translateY(-1000%);
                            }
    
                            .card-des{
                                transform: translateY(1000%);
                            }
                        }
    
                        &:hover{
                            & ~ .card-body{
                                opacity: 1;
                                .card-title,
                                .card-des{
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }

                @include pad{
                    flex-direction: row;
                    align-items: flex-start;
                    background-color: transparent;
                    
                    &-img-top{
                        @include radius(20px);
                        overflow: hidden;
                        flex-basis: 150px;
                        margin-top: 46px;
                    }

                    &-body{
                        padding-left: 1.25em;
                        padding-right: 0.25em;
                        flex: 0 0 calc(100% - 150px);
                    }

                    &-title{
                        @include fontSize(25px);
                    }
                }
            }

            .swiper{
                &-slide{
                    .card{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    
                    @include setMinRwd{
                        &-active,
                        &-next{
                            .card{
                                border-bottom-right-radius: 0 !important
                            }
                        }

                        &-active{
                            .card{
                                border-top-left-radius: 40%;
                            }
                        }

                        &-next{
                            .card{
                                @include radius(20px);
                            }
                        }

                        .card{
                            border-bottom-right-radius: 40%;
                            overflow: hidden;
                        }
                    }
                }

                &-act{
                    &-container{
                        @extend %setFlex;
                        align-items: flex-end;
                        justify-content: end;
                        margin-bottom: 2.75em;
                        padding-right: 1em;

                        .swiper-pagination {
                            position: relative;
                            width: auto;
                            top: 0px;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(100%,1px);
                                background-color: #cbcbcb;
                                position: absolute;
                                top: 25px;
                                z-index: -1;
                            }
                        }
                    }

                    &-button{
                        width: calc(3.5em + 10px);
                        padding-left: 10px;
                        @extend %setFlex-center;
                        justify-content: space-between;
                        .swiper-button-next,
                        .swiper-button-prev {
                            position: static;
                            color: $colorBase;
                            height: auto;
                            margin-top: 0;
                            &::after{
                                font-size: 14px;
                                @extend .fw-900
                            }
                        }
                    }
                }

                &-pagination{
                    &-fraction{
                        span{
                            color: #b9b9b9;
                            font-size: 15px;
                            font-family: $OpenSans-300;
                            padding: 0 2px;
                        }

                        .swiper-pagination-empty{
                            width: 30px;
                        }

                        .swiper-pagination-current{
                            position: relative;
                            color: $colorBase;
                            font-weight: 600;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(30px,3px);
                                background-color: $colorMain;
                                position: absolute;
                                bottom: -2px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                display: flex;
                flex-direction: column;
                .swiper-act-container{
                    order: 1;
                    margin-top: 1.1em;
                }
            }
        }
    }

    // 內容
    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;
        &-list{
            &-frAuto{
                grid-template-columns: repeat(1, auto) 1fr
            }
            
            &-1to1{
                grid-template-columns: 1fr 1fr;
            }

            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }
        }

        @include pad1080{
            grid-template-columns: auto !important;
        }
    }

    &Flex{
        @extend %setFlex;
    }
}

/* swiper index banner */
.swiper-bannerIn{
	width: 100%;
	height: 100%;
    overflow: hidden;

        .swiper-container {
            width: 100%;
            height: 100%;
        }
        
        .swiper-image {
            height: 100%;
        }

        .swiper-image-inner {
            background-size: cover;
            background-position: center center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-blend-mode: overlay;
        }

        .swiper-slide{
            transition: all 1s linear;
            transition-delay: 0.5s;
            opacity: 0;
            overflow: hidden;
        }

        .swiper-slide.swiper-slide-next {
            opacity: 0;
        }

        // .swiper-slide,
        // .swiper-slide.swiper-slide-active + .swiper-slide-next,
        // .swiper-slide.swiper-slide-duplicate-active{
        //     transform: translateX(100%) translateZ(0);
        // }
        
        // .swiper-slide.swiper-slide-duplicate,
        // .swiper-slide.swiper-slide-duplicate-next,
        // .swiper-slide.swiper-slide-duplicate-prev{
        //     transform: translateX(-25%) translateZ(0);
        // }

      
        // .swiper-slide.swiper-slide-next,
        // .swiper-slide.swiper-slide-prev{
        //     opacity: 0.9;
        //     transform: translateX(25%) translateZ(0);

        //     @include setWidthRwd(1100px,1500px){
        //         transform: translateX(20%) translateZ(0);
        //     }
        // }

        // .swiper-slide.swiper-slide-next .swiper-image,
        // .swiper-slide.swiper-slide-prev .swiper-image{
        //     border-top-right-radius: 25em;
        //     overflow: hidden;
        //     &::after{
        //         content: "";
        //         display: block;
        //         width: 100%;
        //         height: 100%;
        //         position: absolute;
        //         top: 0;
        //         background-color: rgba(0, 0, 0, 0.65);
        //         z-index: 1;
        //     }

        //     @include setMaxRwd{
        //         border-top-right-radius: 4.5em;
        //     }
        // }

        .swiper-slide.swiper-slide-active{
            opacity: 1;
            width: 100% !important;
            // max-width: 1550px !important;
            // transform: translateX(24%) translateZ(0);
            // overflow: visible;

            // @include setWidthRwd(1100px,1500px){
            //     transform: translateX(15%) translateZ(0);
            // }

            // @include setMaxRwd{
            //     transform: translateX(20%) translateZ(0);
            // }
        }

        .swiper-slide.swiper-slide-active .swiper-image{
            // border-top-left-radius: 25em;
            overflow: hidden;

            @include setMaxRwd{
                // border-top-left-radius: 4.5em;
            }
        }
        
        .swiper-image.swiper-image-active{
            img{
                width: 100%;
                height: 730px;
                object-fit: cover;

                @include setWidthRwd(1100px,1650px){
                    height: 40em;
                }
            }
        }

        .swiper-image-inner.swiper-image-active {
            transition: all 1s linear;
            transition-delay: 1s;
            background-blend-mode: multiply;
        }

        /* Arrows */
        .swiper-button-prev,
        .swiper-button-next {
            width: 39px;
            transition: var(--fancy-slider-transition-button);
            outline: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            &::after{
                display: none;
            }

            @include setMaxRwd{
                width: 40px;
                opacity: 0.75;
                svg {
                    image{
                        height: 45px;
                    }
                }
            }
        
            svg {
                display: block;
                transition: var(--fancy-slider-transition-button);
            }
        }

        .swiper-button-prev{
            left: 0;
            @include setMaxRwd{
                left: -2%;
            }
        }

        .swiper-button-next{
            right: 0;
            @include setMaxRwd{
                right: -2%;
            }
        }

        .swiper-pagination{
            width: 100%;
            position: absolute;
            text-align: center;
            transition: 300ms;
            transform: translate3d(0, 0, 0);
            z-index: 10;
            &-bullet {
                width: 14px;
                height: 14px;
                background: #fff;
                opacity: 0.4;
                &-active {
                    opacity: 1;
                    background: #fff;
                }
            }
        }
      
}
