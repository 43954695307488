.download{
    &-wrap{
        margin-top: 1.5em;
        margin-bottom: 3em;
    }

    &-list{
        &-group{
            border-radius: 0 !important;
            padding: 1em 1.25em;
            & + .download-list-group {
                border-top-width: 1px !important;
                margin-top: 1em !important;
            }
            
            .icon{
                margin-top: -6px;
            }
    
            &:hover{
                border-color: transparent;
                .download-action{
                    svg{
                        path{
                            fill: #000;
                        }
                    }
                }
            }

            @include setMaxRwd{
                padding: 0.75em 1em;
                .download-head{
                    width: 100%
                }
            }
        }
    }
}